import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ChangePwScreen from "./ChangePwScreen";

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        minHeight: 400
    },
    dialogTitle: {
        paddingRight: '0px'
    }
}));


const ChangePw = () => {
    const [openModal, setOpenModal] = useState(false);
    const classes = useStyles();

    const handleClose = () => {
        setOpenModal(false);
    }

    return (
        <div>
            <Button onClick={()=> setOpenModal(true)} style={{ textTransform: 'capitalize' }}>Αλλαγή κωδικού</Button>
            <Dialog open={openModal} maxWidth="md" classes={{ paper: classes.dialogWrapper }}>
                <DialogTitle className={classes.dialogTitle}>
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Αλλαγή κωδικού!
                        </Typography>
                        <CancelIcon onClick={handleClose} style={{ position: 'absolute', right: 5, top: 5, cursor: 'pointer'}} />
                    </div>
                </DialogTitle>
                <DialogContent>
                   <ChangePwScreen />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ChangePw;
