import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: 700,
    },
    title: {
        marginTop: theme.spacing(2),
    },
}));

export default function Review(props) {
    const classes = useStyles();
    const {reqData} = props;

    const maskCardNumber = () => {
        return reqData.card_number.replace(/^[\d-\s]+(?=\d{4})/, "************");
    }

    const maskedCvv = () => {
        return reqData.cvv.length === 4 ? '****' : '***';
    }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Στοιχεία παραγγελίας
            </Typography>
            <List style={{marginTop: 15}}>
                {reqData.description && <ListItem className={classes.listItem} key={1} divider>
                    <ListItemText primary={'Περιγραφή πληρωμής:'}/>
                    <Typography style={{fontWeight: 'bold'}}>{reqData.description}</Typography>
                </ListItem>}
                {reqData.merchantref && <ListItem className={classes.listItem} key={2} divider>
                    <ListItemText primary={'Κωδικός εμπόρου:'}/>
                    <Typography style={{fontWeight: 'bold'}}>{reqData.merchantref}</Typography>
                </ListItem>}
                {reqData.email && <ListItem className={classes.listItem} key={3} divider>
                    <ListItemText primary={'Email:'}/>
                    <Typography style={{fontWeight: 'bold'}}>{reqData.email}</Typography>
                </ListItem>}
                {reqData.phone && <ListItem className={classes.listItem} key={4} divider>
                    <ListItemText primary={'Τηλέφωνο:'}/>
                    <Typography style={{fontWeight: 'bold'}}>{reqData.phone}</Typography>
                </ListItem>}
                <ListItem className={classes.listItem} key={5} divider>
                    <ListItemText primary={'Τελικό ποσό πληρωμής:'}/>
                    <Typography
                        style={{fontWeight: 'bold'}}>{'€' + reqData.amount.slice(0, -2) + "." + reqData.amount.slice(-2)}</Typography>
                </ListItem>
            </List>
            <Typography variant="h6" gutterBottom>
                Στοιχεία πληρωμής
            </Typography>
            <List style={{marginTop: 15}}>
                <ListItem className={classes.listItem} key={6} divider>
                    <ListItemText primary={'Αριθμός κάρτας:'}/>
                    <Typography style={{fontWeight: 'bold'}}>{maskCardNumber()}</Typography>
                </ListItem>
                 <ListItem className={classes.listItem} key={7} divider>
                    <ListItemText primary={'Ημερομηνία λήξης:'}/>
                    <Typography style={{fontWeight: 'bold'}}>{reqData.expiration_month + ' / ' + reqData.expiration_year}</Typography>
                </ListItem>
                <ListItem className={classes.listItem} key={8} divider>
                    <ListItemText primary={'Κωδικός ασφαλείας:'}/>
                    <Typography style={{fontWeight: 'bold'}}>{maskedCvv()}</Typography>
                </ListItem>
                 <ListItem className={classes.listItem} key={9} divider>
                    <ListItemText primary={'Όνομα κατόχου:'}/>
                    <Typography style={{fontWeight: 'bold'}}>{reqData.holder_name}</Typography>
                </ListItem>
            </List>
        </React.Fragment>
    );
}
