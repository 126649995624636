
import * as React from "react";

function DinersIcon(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 750 471" {...props}>
            <g fill="none">
                <rect fill="#0079BE" width={750} height={471} rx={40} />
                <path
                    d="M584.934 237.947c0-99.415-82.981-168.133-173.895-168.1h-78.242c-92.003-.033-167.73 68.705-167.73 168.1 0 90.93 75.727 165.64 167.73 165.204h78.242c90.914.436 173.895-74.293 173.895-165.204z"
                    fill="#FFF"
                />
                <path
                    d="M333.28 83.93c-84.07.027-152.194 68.308-152.214 152.58.02 84.258 68.144 152.533 152.214 152.56 84.09-.027 152.228-68.302 152.24-152.56-.012-84.272-68.15-152.553-152.24-152.58z"
                    fill="#0079BE"
                />
                <path
                    d="M237.066 236.098c.08-41.18 25.746-76.296 61.94-90.25v180.48c-36.194-13.947-61.861-49.044-61.94-90.23zm131 90.275V145.847c36.207 13.92 61.914 49.057 61.98 90.257-.066 41.212-25.773 76.322-61.98 90.269z"
                    fill="#FFF"
                />
            </g>
        </svg>
    );
}

export default DinersIcon;
