import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputMask from "react-input-mask";

import { keyPress, onPaste } from '../Helpers';
import {CardIcon} from "../CardIcon";

export const CardNumber = ({props}) => {
    function beforeMaskedStateChange({ currentState, nextState }) {
        let { value, selection, enteredString } = nextState;

        if (
            currentState &&
            currentState.value.length >= 12 &&
            currentState.value.length <= 19 &&
            !currentState.value.includes(' ')
        ) {
            value = '';
            selection.start = 20;
            selection.end = 20;
            value =
                currentState.value.slice(0, 4) +
                ' ' +
                currentState.value.slice(4, 8) +
                ' ' +
                currentState.value.slice(8, 12) +
                ' ' +
                currentState.value.slice(12, 16) +
                ' ';
        }

        if (enteredString && enteredString.length === 16) {
            value = '';
            selection.start = 20;
            selection.end = 20;
            value =
                enteredString.slice(0, 4) +
                ' ' +
                enteredString.slice(4, 8) +
                ' ' +
                enteredString.slice(8, 12) +
                ' ' +
                enteredString.slice(12, 16) +
                ' ';
        }

        return {
            ...nextState,
            value,
            selection,
        };
    }

    return (
        <InputMask
            mask={
                props.values.cardNumber && props.values.cardNumber !== ''
                    ? '9999 9999 9999 9999 999'
                    : null
            }
            beforeMaskedStateChange={beforeMaskedStateChange}
            onFocus={props.handleFocus}
            value={props.values.cardNumber}
            onChange={props.handleChange}
            onPaste={onPaste}
            onKeyDown={(e) => keyPress(e)}
            onBlur={(e) => {
                props.handleBlur(e);
                if (props.setFocusedInput) {
                    props.setFocusedInput('');
                }
            }}
            disabled={false}
            maskPlaceholder=""
        >
            <TextField
                id="cardNumber"
                name="cardNumber"
                label="Αριθμός κάρτας*"
                type="text"
                autoComplete="off"
                autoCorrect="off"
                placeholder="1234 1234 1234 1234"
                error={props.touched.cardNumber && Boolean(props.errors.cardNumber)}
                helperText={props.touched.cardNumber && props.errors.cardNumber}
                fullWidth
                InputProps={{
                    endAdornment: <CardIcon {...props} />
                }}
                inputProps={{
                    maxLength: 23,
                    autoCorrect: 'off',
                    spellCheck: 'false',
                    autoComplete: 'off'
                }}
                InputLabelProps={{
                    shrink: true
                }}
            />
        </InputMask>
    );
};
