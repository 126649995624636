import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import authHeader from "../../services/auth.header";
import Success from "../Success";

const validationSchema = yup.object({
    newPassword: yup
        .string()
        .required('Ο νέος κωδικός είναι απαραίτητος')
        .min(8, 'Ο κωδικός πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες')
        .max(100, 'Ο κωδικός μπορεί να είναι μέχρι 100 χαρακτήρες')
        .matches('[0-9]', 'Ο κωδικός πρέπει να έχει τουλάχιστον έναν αριθμό')
        .matches('[A-Z]', 'Ο κωδικός πρέπει να έχει τουλάχιστον ένα κεφαλαίο γράμμα')
        .matches('[*@!#%&()^~{}]+', 'Ο κωδικός πρέπει να έχει τουλάχιστον έναν ειδικό χαρακτήρα'),
    newPasswordConfirmation: yup.string()
        .oneOf([yup.ref('newPassword'), null], 'Οι κωδικοί πρέπει να είναι ίδιοι')
});

const ChangeUserPwScreen = (props) => {
    const [status, setStatus] = useState(undefined);

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            newPasswordConfirmation: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            values.userId = props.userId;
            axios.post(process.env.REACT_APP_API_URL + '/api/auth/changepw-admin', values, {headers: authHeader()}).then(r => {
                console.log('status', r);
                if (r.status === 200) {
                    setStatus('success');
                }
            }).catch(() => {
                setStatus('error')
            })
        },
    });

    return (
        <div>
            <Grid container spacing={3} justify="center" style={{background: '#ffffff'}}>
                {!status && <form onSubmit={formik.handleSubmit}>
                    <TextField
                        fullWidth
                        id="newPassword"
                        name="newPassword"
                        label="Νέος κωδικός"
                        type="password"
                        value={formik.values.newPassword}
                        style={{ marginTop: 15 }}
                        onChange={formik.handleChange}
                        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                        helperText={formik.touched.newPassword && formik.errors.newPassword}
                    />
                    <TextField
                        fullWidth
                        id="newPasswordConfirmation"
                        name="newPasswordConfirmation"
                        label="Επαλήθευση νέου κωδικού"
                        type="password"
                        value={formik.values.newPasswordConfirmation}
                        onChange={formik.handleChange}
                        style={{ marginTop: 15 }}
                        error={formik.touched.newPasswordConfirmation && Boolean(formik.errors.newPasswordConfirmation)}
                        helperText={formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation}
                    />
                    <div style={{marginTop: 15}}>
                        <Button color="primary" variant="contained" type="submit">
                            Συνέχεια
                        </Button>
                    </div>
                </form>}
                {status && <Success status={status}/>}
                {status && <Button color="primary" variant="contained" onClick={() => setStatus(undefined)}
                                   style={{marginTop: 40}}>
                    Πίσω
                </Button>}
            </Grid>
        </div>
    );
};

export default ChangeUserPwScreen;
