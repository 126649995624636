export default function authHeader() {
    const user = JSON.parse(sessionStorage.getItem('userEPVpos'));

    if (user && user.accessToken) {
        // for Node.js Express back-end
        return { 'x-access-token': user.accessToken };
    } else {
        return {};
    }
}
