import valid from 'card-validator';
import VisaIcon from "./images/VisaIcon";
import MastercardIcon from "./images/MastercardIcon";
import DiscoverIcon from "./images/DiscoverIcon";
import DinersIcon from "./images/DinersIcon";
import StaticCardPlaceholder from "./images/StaticCardPlaceholder";

const size = 35;

export const keyPress = (e) => {
    let charCode = e.which || e.keyCode || e.key.charCodeAt(0);

    //allow only numbers, ctrl+v/ctrl+a/ctrl+c
    if (
        !(charCode >= 96 && charCode <= 105) &&
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        !(
            (charCode === 65 ||
                charCode === 86 ||
                charCode === 67 ||
                charCode === 88 ||
                charCode === 82) &&
            (e.ctrlKey || e.metaKey)
        ) &&
        charCode !== 37 &&
        charCode !== 39
    ) {
        e.preventDefault();
    }
};

export const onPaste = (e) => {
    if (!onlyNumbers(e.clipboardData.getData('Text'))) {
        e.preventDefault();
    }
};

export const onlyNumbers = (s) => {
    if (!s) return null;
    const strippedString = s.replace(/\s/g, '');
    return strippedString.match(/^\d+$/);
};

export const cardIcon = (type) => {
    let cardType;
        cardType = (
            <div>
                <StaticCardPlaceholder
                    style={{ width: size, height: size, paddingRight: 3 }}
                />
            </div>
        );

    return getCardType(type, cardType);
};

export const getCardType = (type, defaultCase) => {
    const visaImg = (
        <div style={{ width: size, height: size, paddingLeft: 3}}>
            <VisaIcon style={{ width: size, height: size }} />
        </div>
    );
    const mastercardImg = (
        <MastercardIcon style={{ width: size, height: size, paddingLeft: 3 }} />
    );
    const discoverImg = (
        <DiscoverIcon style={{ width: size, height: size, paddingLeft: 3 }} />
    );
    const dinersImg = (
        <DinersIcon style={{ width: size, height: size, paddingLeft: 3 }} />
    );

    switch (type) {
        case 'visa':
            return <div> {visaImg} </div>;
        case 'mastercard':
            return <div> {mastercardImg} </div>;
        case 'discover':
            return <div> {discoverImg} </div>;
        case 'diners-club':
            return <div> {dinersImg} </div>;
        default:
            return defaultCase;
    }
};

export const getIcon = (p) => {
    if (!p) return null;

    return cardIcon(
        valid.number(p.values.cardNumber).card
            ? // @ts-ignore
            valid.number(p.values.cardNumber).card.type
            : ''
    );
};
