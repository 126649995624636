import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import authHeader from "../services/auth.header";
import Success from "./Success";
import Grid from "@material-ui/core/Grid";
import {CardNumber} from "./Inputs/CardNumber";
import {CircularProgress, createMuiTheme, Step, StepLabel, Stepper, ThemeProvider, Typography} from "@material-ui/core";
import {ExpiryDate} from "./Inputs/ExpiryDate";
import valid from "card-validator";
import Review from "./Review";
import {makeStyles} from "@material-ui/core/styles";

const validationSchema = yup.object({
    amount: yup.string()
        .trim()
        .required('Το πεδίο είναι απαραίτητο')
        .test(
            '1 dot or comma',
            'Το ποσό δέχεται μόνο 1 κόμμα η τελεία για τα cents',
            (value) => {
                if (value) {
                    const commaCount = value.split(',').length - 1;
                    const dotCount = value.split('.').length - 1;
                    return commaCount + dotCount <= 1;
                }
            }
        )
        .test(
            'only 2 numbers after dot or comma',
            'Το ποσό δέχεται μέχρι 2 χαρακτήρες στα cents',
            (value) => {
                if (value) {
                    if (value.includes(',')) {
                        return value.split(',')[1].length <= 2;

                    }
                    if (value.includes('.')) {
                        return value.split('.')[1].length <= 2;
                    }
                    return true;
                }
            }
        ),
    description: yup.string().required('Παρακαλώ συμπληρώστε το πεδίο Περιγραφή πληρωμής'),
    email: yup.string().email('Η διεύθυνση email δεν είναι σωστή'),
    phone: yup.string(),
    cardNumber: yup.string()
        .test(
            'cardNumber',
            'Ο αριθμός της κάρτας δεν είναι σωστός',
            (value) => valid.number(value).isValid
        )
        .required('Ο αριθμός της κάρτας είναι υποχρεωτικός'),
    expiryDate: yup.string()
        .test(
            'expiryDate',
            'Η ημερομηνία λήξης δεν είναι σωστή',
            (value) => valid.expirationDate(value).isValid
        )
        .required('Η ημερομηνία λήξης είναι υποχρεωτική'),
    cvv: yup.string()
        .min(3, 'Ο κωδικός ασφαλείας δεν είναι σωστός')
        .max(4, 'Ο κωδικός ασφαλείας δεν είναι σωστός')
        .required('Ο κωδικός ασφαλείας είναι υποχρεωτικός'),
    name: yup.string().required('Το όνομα κατόχου είναι υποχρεωτικό'),
});

const useStyles = makeStyles((theme) => ({
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
}));

const theme = createMuiTheme({
    palette: {
        action: {
            disabledBackground: '#3f51b5',
            disabled: '#ffffff'
        }
    }
});

const steps = ['Εισαγωγή στοιχείων', 'Επαλήθευση στοιχείων', 'Αποτέλεσμα συναλλαγής'];

const MotoForm = () => {
        const classes = useStyles();
        const [status, setStatus] = useState(undefined);
        const [respData, setRespData] = useState(undefined);
        const [activeStep, setActiveStep] = useState(0);
        const [reqData, setReqData] = useState(undefined);
        const [loading, setLoading] = useState(false);

        const handleNext = () => {
            setActiveStep(activeStep + 1);
        };

        const handleBack = () => {
            setActiveStep(activeStep - 1);
        };

        const formik = useFormik({
            initialValues: {
                amount: '',
                description: '',
                email: '',
                phone: '',
                cardNumber: '',
                expiryDate: '',
                cvv: '',
                name: ''
            },
            validationSchema: validationSchema,
            onSubmit: (values) => {
                const cardData = {
                    cardNumber: values.cardNumber,
                    expiryDate: values.expiryDate,
                    name: values.name,
                    cvv: values.cvv,
                }

                const cardDataSanitized = cardDataSanitization(cardData);

                const reqBody = {
                    amount: amountSanitized(values.amount),
                    description: values.description,
                    email: values.email,
                    phone: values.phone,
                    ...cardDataSanitized
                }
                setReqData(reqBody);
                handleNext();
            },
        });

        const amountSanitized = (val) => {
            if (val.includes(',')) {
                if (val.split(',')[1].length === 2) {
                    return val.replace(',', '');
                }
                return val.replace(',', '') + '0';

            }
            if (val.includes('.')) {
                if (val.split('.')[1].length === 2) {
                    return val.replace('.', '');
                }
                return val.replace('.', '') + '0';
            }
            return val + '00';
        }

        const cardDataSanitization = (i) => {
            if (!i) return {};

            let expiration_year;
            let expiration_month;

            if (i.expiryDate && i.expiryDate.split('/')[0].length === 1) {
                expiration_month = '0' + i.expiryDate.split('/')[0];
                i.expiryDate = '0' + i.expiryDate;
            }
            if (i.expiryDate && i.expiryDate.split('/')[1] !== undefined) {
                if (i.expiryDate.split('/')[1].length >= 3) {
                    expiration_year = i.expiryDate && i.expiryDate[5] + i.expiryDate[6];
                    expiration_month = i.expiryDate && i.expiryDate[0] + i.expiryDate[1];
                }
                if (i.expiryDate.split('/')[1].length === 2) {
                    expiration_year = i.expiryDate && i.expiryDate[3] + i.expiryDate[4];
                    expiration_month = i.expiryDate && i.expiryDate[0] + i.expiryDate[1];
                }
            }

            return {
                card_number: i.cardNumber,
                expiration_year,
                expiration_month,
                holder_name: i.name,
                cvv: i.cvv,
            };
        };

        const authorizePayment = () => {
            setLoading(true);
            axios.post(process.env.REACT_APP_API_URL + '/api/pay', reqData, {headers: authHeader()}).then(r => {
                if (r.status === 200) {
                    const successBody = {
                        token: r.data.body.token,
                        status: r.data.body.status,
                        description: r.data.body.description,
                    }
                    setRespData(successBody);
                    setTimeout(() => setLoading(false), 1200);
                    setStatus('success');
                    handleNext();
                }
            }).catch(e => {
                setRespData(e.response.data);
                setTimeout(() => setLoading(false), 1200);
                setStatus('error')
                handleNext();
            })
        }

        return (
            <React.Fragment>
                <Stepper activeStep={activeStep} className={classes.stepper}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === 0 && <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{marginTop: 35, textAlign: 'center'}}>Στοιχεία
                                συναλλαγής</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="amount"
                                name="amount"
                                label="Ποσό Πληρωμής*"
                                placeholder="0.00"
                                autoComplete="new-password"
                                value={formik.values.amount}
                                onChange={formik.handleChange}
                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                helperText={formik.touched.amount && formik.errors.amount}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="description"
                                name="description"
                                label="Περιγραφή πληρωμής"
                                autoComplete="new-password"
                                placeholder=""
                                multiline={true}
                                helperText="π.χ. κωδικός ηλεκτρονικής παραγγελίας"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="email"
                                name="email"
                                label="Email"
                                autoComplete="new-password"
                                placeholder="jdoe@example.com"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="phone"
                                name="phone"
                                label="Τηλέφωνο"
                                autoComplete="new-password"
                                placeholder="+30 2101111111"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{marginTop: 35, textAlign: 'center'}}>Στοιχεία
                                κάρτας</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <CardNumber props={formik}/>
                        </Grid>
                        <Grid item xs={6}>
                            <ExpiryDate props={formik}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="cvv"
                                name="cvv"
                                label="Κωδικός ασφαλείας*"
                                autoComplete="off"
                                type="password"
                                placeholder="123"
                                value={formik.values.cvv}
                                onChange={formik.handleChange}
                                error={formik.touched.cvv && Boolean(formik.errors.cvv)}
                                helperText={formik.touched.cvv && Boolean(formik.errors.cvv) ? formik.touched.cvv && formik.errors.cvv : 'CVV - τα 3 ή 4 ψηφία στο πίσω μέρος της κάρτας'}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="name"
                                name="name"
                                label="Όνομα κατόχου*"
                                autoComplete="off"
                                placeholder="John Doe"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: 20}}>
                            <Button color="primary" variant="contained" type="submit" fullWidth
                                    style={{textTransform: 'capitalize'}}>
                                Επαλήθευση συναλλαγής
                            </Button>
                        </Grid>
                    </Grid>
                </form>}
                {activeStep === 1 && <Review reqData={reqData}/>}
                {activeStep === 1 && <Grid item xs={12} style={{marginTop: 20}}>
                    <ThemeProvider theme={theme}>
                        <Button color="primary" variant="contained" onClick={authorizePayment} fullWidth
                                style={{textTransform: 'capitalize', marginTop: 20}} disabled={loading}>
                            Ολοκλήρωση συναλλαγής {loading &&
                        <CircularProgress size={20} style={{color: '#ffffff', marginLeft: 15}}/>}
                        </Button>
                    </ThemeProvider>
                </Grid>}
                {activeStep === 2 && <Success status={status} respData={respData}/>}
                {activeStep === 2 && (
                    <ThemeProvider theme={theme}>
                        <Button color="primary" variant="contained" disabled={loading} fullWidth onClick={() => {
                            setActiveStep(0);
                            formik.resetForm();
                            setReqData(undefined)
                        }} className={classes.button} style={{textTransform: 'capitalize', marginTop: 70}}>
                            Νέα συναλλαγή {loading &&
                        <CircularProgress size={20} style={{color: '#ffffff', marginLeft: 15}}/>}
                        </Button>
                    </ThemeProvider>
                )}
                {activeStep === 1 && (
                    <Button color="primary" variant="contained" fullWidth onClick={handleBack} className={classes.button}
                            style={{textTransform: 'capitalize', marginTop: 35}}>
                        Πίσω
                    </Button>
                )}
                {activeStep === 2 && status !== 'success' && (
                    <Button color="primary" variant="contained" fullWidth onClick={handleBack} className={classes.button}
                            style={{textTransform: 'capitalize', marginTop: 35}}>
                        Πίσω
                    </Button>
                )}
            </React.Fragment>
        );
    }
;

export default MotoForm;
