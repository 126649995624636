
import * as React from 'react';

function StaticCardPlaceholder(props) {
    return (
        <svg
            id="static_card_icon_svg__eddf7e01-8703-479f-9a73-4bd8a0792c0b"
            data-name="bbdbdba2-06ba-437b-b4a8-17df0071d8f3"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 160.68 101.94"
            width="1em"
            height="1em"
            {...props}
        >
            <defs>
                <style>
                    {
                        '.static_card_icon_svg__b8947eb9-13ef-49aa-a934-4859bdb38726{fill:#fcfdff}'
                    }
                </style>
            </defs>
            <path
                d="M160.68 51v39.49a11.45 11.45 0 01-11.45 11.45H11.45A11.44 11.44 0 010 90.51v-79A11.44 11.44 0 0111.39 0h137.84a11.45 11.45 0 0111.45 11.45z"
                fill="#ced1d2"
            />
            <path
                className="static_card_icon_svg__b8947eb9-13ef-49aa-a934-4859bdb38726"
                d="M0 58.98h160.68v20.29H0zM27.22 19.37h8.09a5 5 0 015 5V39a5 5 0 01-5 5H20.63a5 5 0 01-5-5V24.33a5 5 0 015-5h6.59"
            />
        </svg>
    );
}

export default StaticCardPlaceholder;
