import React from 'react';
import Grid from '@material-ui/core/Grid';
import CheckOutlineCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: 700,
    },
    title: {
        marginTop: theme.spacing(2),
    },
}));

const Success = (props) => {
    const classes = useStyles();
    const { status, respData } = props;

    return (
        <React.Fragment>
            {status === 'success' && <Grid container spacing={2} style={{marginTop: 15}}>
                <Grid item xs={12}>
                    <CheckOutlineCircleIcon style={{color: 'green', fontSize: 60}}/>
                </Grid>
                <Grid item xs={12}>
                    <b>Επιτυχία!</b> <br/>
                    Ολοκλήρωση αιτήματος με επιτυχία!
                    {respData && <React.Fragment>
                        <Grid item container direction="column" xs={12} style={{ marginTop: 30}}>
                            <Typography gutterBottom className={classes.title}>
                                Πληροφορίες συναλλαγής
                            </Typography>
                            <Grid container style={{ marginTop: 15}}>
                                {Object.entries(respData).map((e) => (
                                    <React.Fragment key={e[0]}>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>{e[0]}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom style={{ fontWeight: 'bold', textAlign: 'left'}}>{e[1]}</Typography>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </Grid>
                    </React.Fragment>}
                </Grid>
            </Grid>}
            {status === 'error' && <Grid container spacing={2} style={{marginTop: 15}}>
                <Grid item xs={12}>
                    <ErrorOutlineIcon style={{color: 'red', fontSize: 60}}/>
                </Grid>
                <Grid item xs={12}>
                    <b>Πρόβλημα!</b> <br/>
                    <b>Υπήρξε κάποιο πρόβλημα!</b>

                    {respData && <React.Fragment>
                        <Grid item container direction="column" xs={12} style={{ marginTop: 30}}>
                            <Typography gutterBottom className={classes.title}>
                                Πληροφορίες προβλήματος
                            </Typography>
                            <Grid container style={{ marginTop: 15}}>
                                {Object.entries(respData).map((e) => (
                                    <React.Fragment key={e[0]}>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>{e[0]}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom style={{ fontWeight: 'bold', textAlign: 'left'}}>{e[1]}</Typography>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </Grid>
                    </React.Fragment>}
                </Grid>
            </Grid>}
        </React.Fragment>
    );
}

export default Success;
