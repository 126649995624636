import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AuthService from '../services/auth.service';
import {useHistory} from "react-router-dom";
import {Box, Container, CssBaseline, Link, makeStyles, Typography} from "@material-ui/core";
import logo from './images/ep_logo.png';

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        // .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        // .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
});

function Footer() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'V-POS @ '}
            <Link color="inherit" href="https://everypay.gr/">
                EveryPay
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        fontWeight: 'bold',
        textTransform: 'capitalize'
    },
}));

const Login = () => {
    const history = useHistory();
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            AuthService.login(values.email, values.password).then(u => {
                if(u) {
                    if (u.defaultPw) {
                        return history.push('/changepw');
                    }
                    if (u.roles.includes('ROLE_ADMIN')) {
                        return history.push('/admin');
                    } else {
                        return history.push('/');
                    }
                }
            })
        },
    });

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                    <img src={logo} style={{ width: 400 }} alt="everypay"/>
                <Typography component="h1" variant="h6" style={{ marginTop: 20, marginBottom: 20 }}>
                    Είσοδος χρήστη
                </Typography>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    id="password"
                    name="password"
                    label="Κωδικός"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Σύνδεση
                </Button>
            </form>
        </div>
            <Box mt={8}>
                <Footer />
            </Box>
        </Container>
    );
};

export default Login;
