import React, {useState} from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import authHeader from "../../services/auth.header";
import Success from "../Success";
import Grid from "@material-ui/core/Grid";

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .required('Email is required'),
    password:  yup
        .string()
        .required('Ο νέος κωδικός είναι απαραίτητος')
        .min(8, 'Ο κωδικός πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες')
        .max(100, 'Ο κωδικός μπορεί να είναι μέχρι 100 χαρακτήρες')
        .matches('[0-9]', 'Ο κωδικός πρέπει να έχει τουλάχιστον έναν αριθμό')
        .matches('[A-Z]', 'Ο κωδικός πρέπει να έχει τουλάχιστον ένα κεφαλαίο γράμμα')
        .matches('[*@!#%&()^~{}]+', 'Ο κωδικός πρέπει να έχει τουλάχιστον έναν ειδικό χαρακτήρα'),
    orgName: yup.string().required(),
    orgSk: yup.string().required()
});

const Admin = () => {
    const [status, setStatus] = useState(undefined);
    const [respData, setRespData] = useState(undefined);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            orgName: '',
            orgSk: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            axios.post(process.env.REACT_APP_API_URL + '/api/auth/signupOrg', values, { headers: authHeader()}).then(r => {
                if(r.status === 200) {
                    setStatus('success');
                    setRespData({ message: 'Successfully Created User and Org'})
                }
            }).catch(e => {
                setRespData(e.response.data)
                setStatus('error')
            })
        },
    });

    return (
        <div>
            <Grid container spacing={3}>
            {!status && <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                />
                <TextField
                    fullWidth
                    id="orgName"
                    name="orgName"
                    label="Organization name"
                    value={formik.values.orgName}
                    onChange={formik.handleChange}
                    error={formik.touched.orgName && Boolean(formik.errors.orgName)}
                    helperText={formik.touched.orgName && formik.errors.orgName}
                />
                <TextField
                    fullWidth
                    id="orgSk"
                    name="orgSk"
                    label="Organization Shared Key"
                    value={formik.values.orgSk}
                    onChange={formik.handleChange}
                    error={formik.touched.orgSk && Boolean(formik.errors.orgSk)}
                    helperText={formik.touched.orgSk && formik.errors.orgSk}
                />
                <div style={{ marginTop: 15}}>
                <Button color="primary" variant="contained" type="submit">
                    Submit
                </Button>
                </div>
            </form>}
            {status && <Success status={status} respData={respData}/>}
                {status && <Button color="primary" variant="contained" onClick={() => setStatus(undefined)} style={{ marginTop: 40}}>
                    Back
                </Button>}
            </Grid>
        </div>
    );
};

export default Admin;
