import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Admin from "./Admin";
import AuthService from "../../services/auth.service";
import {useHistory} from "react-router-dom";
import logo from "../images/ep_logo.png";
import ChangePw from "../ChangePw";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'V-POS © '}
            <Link color="inherit" href="https://everypay.gr/">
                EveryPay
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));


export default function PageWrapper() {
    const classes = useStyles();
    const history = useHistory();

    const logOut = () => {
        AuthService.logout();
        history.push('/login');
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="absolute" color="default" className={classes.appBar}>
                <Toolbar>
                    <div className={classes.appBarTitle}>
                        <img src={logo} alt="everypay" style={{width: 150}}/>
                    </div>
                    <ChangePw/>
                    <Button onClick={logOut} style={{textTransform: 'capitalize'}}>Αποσύνδεση</Button>
                </Toolbar>
            </AppBar>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h6" variant="h6" align="center">
                        Org & User admin creation
                    </Typography>
                    <Admin />
                </Paper>
                <Copyright />
            </main>
        </React.Fragment>
    );
}
