import axios from "axios";
import authHeader from "./auth.header";

const API_URL = process.env.REACT_APP_API_URL + "/api/auth/";


const login = (email, password) => {
    return axios
        .post(API_URL + "signin", {
            email,
            password,
        })
        .then((response) => {
            if (response.data.accessToken) {
                sessionStorage.clear();
                sessionStorage.setItem('timeWhen', new Date().toISOString());
                sessionStorage.setItem("userEPVpos", JSON.stringify(response.data));
            }

            return response.data;
        }).catch((e) => {
            return alert('Το email ή ο κωδικός δεν είναι σωστός!')
        });
};

const logout = () => {
    sessionStorage.clear();
};

const refresh = () => {
    return  axios.post(API_URL + 'refresh', {}, { headers: authHeader()}).then(r => {
        if(r.status === 200) {
            let cUser = JSON.parse(sessionStorage.getItem("userEPVpos"));
            cUser.accessToken = r.data.accessToken;
            sessionStorage.setItem('timeWhen', new Date().toISOString());
            sessionStorage.setItem("userEPVpos", JSON.stringify(cUser));
        }
    }).catch(e => {
        logout();
    })
}

const changePw = () => {
    let cUser = JSON.parse(sessionStorage.getItem("userEPVpos"));
    cUser.defaultPw = false;
    sessionStorage.setItem("userEPVpos", JSON.stringify(cUser));
}


const getCurrentUser = () => {
    if(!JSON.parse(sessionStorage.getItem("userEPVpos"))) {
        return undefined;
    }

    return JSON.parse(sessionStorage.getItem("userEPVpos"));
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    login,
    logout,
    refresh,
    changePw,
    getCurrentUser,
};
