import React from 'react';
import TextField from '@material-ui/core/TextField';
import { keyPress, onPaste } from '../Helpers';
import InputMask from 'react-input-mask';

export const ExpiryDate = ({props}) => {
    function beforeMaskedStateChange({ currentState, nextState }) {
        let { value, selection, enteredString } = nextState;

        const i = props.values;

        //case for year 4 characters
        if (
            i.expiryDate.split('/')[1] !== undefined &&
            i.expiryDate.split('/')[1].length === 4
        ) {
            value =
                i.expiryDate.split('/')[0] +
                '/' +
                i.expiryDate.split('/')[1].slice(2, 4);
        }

        //check if month has no 0 infront and add it
        if (
            i.expiryDate.split('/')[0] !== undefined &&
            i.expiryDate.split('/')[0].length === 1
        ) {
            //case for year 4 characters
            if (
                i.expiryDate &&
                i.expiryDate.split('/')[1] !== undefined &&
                i.expiryDate.split('/')[1].length > 2
            ) {
                value =
                    '0' +
                    i.expiryDate.split('/')[0] +
                    '/' +
                    i.expiryDate.split('/')[1].slice(2, 4);
            }
            //case for year 2 characters
            if (
                i.expiryDate &&
                i.expiryDate.split('/')[1] !== undefined &&
                i.expiryDate.split('/')[1].length === 2
            ) {
                value =
                    '0' + i.expiryDate.split('/')[0] + '/' + i.expiryDate.split('/')[1];
            }
        }

        if (
            currentState &&
            currentState.value.replace('/', '').length === 6 &&
            !currentState.value.includes(' ')
        ) {
            selection.start = 7;
            selection.end = 7;
            value =
                currentState.value.replace('/', '').slice(0, 2) +
                ' / ' +
                currentState.value.replace('/', '').slice(4, 6);

            return {
                ...nextState,
                value,
                selection,
            };
        }

        if (
            currentState &&
            currentState.value.replace('/', '').length === 4 &&
            !currentState.value.includes(' ')
        ) {
            selection.start = 7;
            selection.end = 7;
            value =
                currentState.value.replace('/', '').slice(0, 2) +
                ' / ' +
                currentState.value.replace('/', '').slice(2, 4);

            return {
                ...nextState,
                value,
                selection,
            };
        }

        if (enteredString && enteredString.length === 6) {
            value = '';
            selection.start = 7;
            selection.end = 7;
            value = enteredString.slice(0, 2) + ' / ' + enteredString.slice(4, 6);

            return {
                ...nextState,
                value,
                selection,
            };
        }

        if (enteredString && enteredString.length === 4) {
            value = '';
            selection.start = 7;
            selection.end = 7;
            value = enteredString.slice(0, 2) + ' / ' + enteredString.slice(2, 4);

            return {
                ...nextState,
                value,
                selection,
            };
        }

        if (value.length === 1 && parseInt(value) > 1) {
            value = '0' + value;
            selection.start = 5;
            selection.end = 5;
        }

        if (value.length > 2 && value.slice(0, 2) > 12) {
            value = '';
        }

        return {
            ...nextState,
            value,
            selection,
        };
    }

    return (
        <InputMask
            mask={
                props.values.expiryDate && props.values.expiryDate !== ''
                    ? '99 / 99'
                    : null
            }
            onFocus={props.handleFocus}
            value={props.values.expiryDate}
            beforeMaskedStateChange={beforeMaskedStateChange}
            onChange={props.handleChange}
            onPaste={onPaste}
            onKeyDown={(e) => keyPress(e)}
            onBlur={(e) => {
                props.handleBlur(e);
                if (props.setFocusedInput) {
                    props.setFocusedInput('');
                }
            }}
            disabled={false}
            maskPlaceholder=""
        >
            <TextField
                id="expiryDate"
                name="expiryDate"
                value={props.values.expiryDate}
                label="Ημερομηνία λήξης*"
                placeholder="ΜΜ / ΕΕ"
                autoComplete="off"
                autoCorrect="off"
                fullWidth
                error={props.touched.expiryDate && Boolean(props.errors.expiryDate)}
                helperText={props.touched.expiryDate && props.errors.expiryDate}
                spellCheck="false"
                inputProps={{
                    maxLength: 7,
                    autoCorrect: 'off',
                    inputMode: 'numeric',
                    spellCheck: 'false',
                    autoComplete: 'off'
                }}
                style={{marginTop: 10}}
                InputLabelProps={{
                    shrink: true
                }}
            />
        </InputMask>
    );
};
