import React, {useEffect, useState} from 'react';
import AuthService from '../services/auth.service';
import {useHistory} from "react-router-dom";
import {
    Avatar,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText, Tab, Tabs
} from "@material-ui/core";
import axios from "axios";
import authHeader from "../services/auth.header";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PersonIcon from '@material-ui/icons/Person';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import CreateUser from "./CreateUser";
import ChangePw from './ChangePw';
import MotoForm from "./MotoForm";
import {TabContext, TabPanel} from "@material-ui/lab";
import RefreshIcon from '@material-ui/icons/Refresh';
import ChangeUserPw from "./Moderator/ChangeUserPw";
import logo from './images/ep_logo.png';
import moment from 'moment';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'V-POS © '}
            <Link color="inherit" href="https://everypay.gr/">
                EveryPay
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    appBarTitle: {
        flexGrow: 1,
        textAlign: "left"
    },
}));

const Moto = () => {
    const classes = useStyles();
    const history = useHistory();
    const [userList, setUserList] = useState(undefined);
    const [userCreate, setUserCreate] = useState(false);
    const [tabValue, setTabValue] = useState('0');
    const [tabIndex, setTabIndex] = useState(0);
    const [timeLeft, setTimeLeft] = useState( 1800 - moment().diff(sessionStorage.getItem('timeWhen'), 'seconds'));

    const logOut = () => {
        AuthService.logout();
        history.push('/login');
    };

    const isMod = AuthService.getCurrentUser() ? AuthService.getCurrentUser().roles.includes('ROLE_MODERATOR') : logOut();


    useEffect(() => {
        console.log(timeLeft);
        if (isMod) {
            getUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (timeLeft <= 0) {
            return logOut();
        }

        const intervalId = setInterval(() => {
            setTimeLeft(1800 - moment().diff(sessionStorage.getItem('timeWhen'), 'seconds'));
        }, 995);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLeft]);

    const getUsers = () => {
        setUserCreate(false);
        axios.get(process.env.REACT_APP_API_URL + '/api/auth/orgusers', {headers: authHeader()}).then(r => {
            if (r.status === 200) {
                const cUser = AuthService.getCurrentUser();
                setUserList(r.data.filter(i => i.id !== cUser.id));
            }
        }).catch(e => {
            console.log(e);
        })
    }

    const createNewUser = () => {
        setUserList(undefined);
        setUserCreate(true);
    }

    const refreshSession = () => {
        setTimeLeft(1800);
        sessionStorage.setItem('timeleft', '1800');
        AuthService.refresh();
    }

    const handleChange = (event, newValue) => {
        if (newValue === 0) {
            getUsers();
        }
        if (newValue === 1) {
            createNewUser();
        }
        setTabValue(newValue.toString());
        setTabIndex(newValue);
    };

    const changeUserStatus = (i) => {
        axios.post(process.env.REACT_APP_API_URL + '/api/auth/change-status', {
            userId: i.id,
            enabled: !i.enabled
        }, {headers: authHeader()}).then(r => {
            if (r.status === 200) {
                getUsers();
            }
        }).catch(e => {
            alert('Υπήρξε κάποιο πρόβλημα, παρακαλώ αποσυνδεθείτε και ξανασυνδεθείτε για να προχωρήσετε!')
        })
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <AppBar position="absolute" color="default" className={classes.appBar}>
                <Toolbar>
                    <div className={classes.appBarTitle}>
                        <img src={logo} style={{width: 150}} alt="everypay"/>
                    </div>
                    <div>{'Αποσύνδεση σε ' + new Date((timeLeft ? timeLeft : 0) * 1000).toISOString().substr(11, 8)}</div>
                    <IconButton color="primary" onClick={() => refreshSession()} component="span">
                        <RefreshIcon/>
                    </IconButton>
                    <ChangePw/>
                    <Button onClick={logOut} style={{textTransform: 'capitalize'}}>Αποσύνδεση</Button>
                </Toolbar>
            </AppBar>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    {isMod && <TabContext value={tabValue}>
                        <Tabs value={tabIndex}
                              onChange={handleChange}
                              indicatorColor="primary"
                              textColor="primary"
                              centered>
                            <Tab label="Λίστα χρηστών" style={{ textTransform: 'capitalize'}}/>
                            <Tab label="Δημιουργία χρήστη" style={{ textTransform: 'capitalize'}}/>
                        </Tabs>
                        <TabPanel value="0">{userList && <Grid item xs={12}>
                            <div className={classes.demo}>
                                <List dense={true}>
                                    {(!userList || userList.length === 0) && <div>Δεν υπάρχουν χρήστες!</div>}
                                    {userList.map((item) => (
                                        <ListItem key={item.email} divider>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PersonIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={item.email}
                                            />
                                            <ListItemSecondaryAction>
                                                <Grid container direction="row">
                                                    <Grid item xs={8}>
                                                        <ChangeUserPw userId={item.id}/>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <IconButton edge="end" aria-label="change user status"
                                                                    alt="change user status"
                                                                    onClick={() => changeUserStatus(item)}>
                                                            {item.enabled === true ? <RemoveCircleOutlineIcon/> :
                                                                <PersonAddIcon/>}
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </ListItemSecondaryAction>
                                        </ListItem>)
                                    )}
                                </List>
                            </div>
                        </Grid>}</TabPanel>
                        <TabPanel value="1">{userCreate && <Grid item xs={12}>
                            <div className={classes.demo}>
                                <CreateUser/>
                            </div>
                        </Grid>}</TabPanel>
                    </TabContext>}
                    {!isMod && <MotoForm/>}
                </Paper>
                <Copyright/>
            </main>
        </React.Fragment>
    );
};

export default Moto;
