import React from 'react';
import { getIcon } from './Helpers';

export const CardIcon = (props) => {
    return (
        <div style={{ whiteSpace: 'nowrap', marginTop: 3}}>
            {getIcon(props)}
        </div>
    );
};
