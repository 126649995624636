import './App.css';
import {Switch, Route, useHistory, Redirect} from 'react-router-dom';
import Login from "./components/Login";
import Moto from "./components/Moto";
import {useEffect} from "react";
import AuthService from './services/auth.service';
import AdminScreen from "./components/Admin/AdminScreen";
import ChangePwScreen from "./components/ChangePwScreen";
import {Typography} from "@material-ui/core";

function App() {
    const history = useHistory();

    useEffect(() => {
        const cUser = AuthService.getCurrentUser();
        if(cUser) {
            if (cUser.defaultPw) {
                return history.push('/changePw');
            }
           if(cUser.roles.includes('ROLE_ADMIN')) {
               return history.push('/admin');
           }
        }
        else {
           return history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className="App">
            <Switch>
                <Route
                    exact
                    path="/login"
                    render={() => <Login />}
                />
                <Route exact path="/admin" component={AdminScreen} />
                <Route exact path="/" component={Moto} />
                <Route exact path="/changepw" render={() => <div style={{ marginTop: 50}}><Typography variant="h5" style={{ textAlign: 'center', marginBottom: 30}}>Αλλαγή κωδικού και συνέχεια!</Typography><ChangePwScreen firstPw={true} /></div>} />
                <Route exact path="*" render={() => <Redirect to="/"/>}/>
            </Switch>
        </div>
    );
}

export default App;
