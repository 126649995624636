import React, {useState} from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import authHeader from "../services/auth.header";
import Success from "./Success";
import Grid from "@material-ui/core/Grid";

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Η διεύθυνση email δεν είναι σωστή')
        .required('Η διεύθυνση email είναι απαραίτητη'),
    password: yup
        .string()
        .required('Ο νέος κωδικός είναι απαραίτητος')
        .min(8, 'Ο κωδικός πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες')
        .max(100, 'Ο κωδικός μπορεί να είναι μέχρι 100 χαρακτήρες')
        .matches('[0-9]', 'Ο κωδικός πρέπει να έχει τουλάχιστον έναν αριθμό')
        .matches('[A-Z]', 'Ο κωδικός πρέπει να έχει τουλάχιστον ένα κεφαλαίο γράμμα')
        .matches('[*@!#%&()^~{}]+', 'Ο κωδικός πρέπει να έχει τουλάχιστον έναν ειδικό χαρακτήρα'),
});

const CreateUser = () => {
    const [status, setStatus] = useState(undefined);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            axios.post(process.env.REACT_APP_API_URL + '/api/auth/signup', values, { headers: authHeader()}).then(r => {
                if(r.status === 200) {
                    setStatus('success');
                }
            }).catch(e => {
                console.log(e);
                setStatus('error')
            })
        },
    });

    return (
        <div>
            <Grid container spacing={3} justify="center">
                {!status && <form onSubmit={formik.handleSubmit}>
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        label="Κωδικός πρόσβασης - (ελάχιστο: 8 χαρακτήρες)"
                        type="password"
                        style={{ marginTop: 15 }}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={(formik.touched.password && formik.errors.password) ? formik.errors.password : 'Ο κωδικός πρέπει περιέχει τουλάχιστον ένα κεφαλαίο γράμμα, έναν αριθμό, έναν ειδικό χαρακτήρα'}
                    />
                    <div style={{ marginTop: 15}}>
                        <Button color="primary" variant="contained" type="submit" style={{ textTransform: 'capitalize'}}>
                            Δημιουργία χρήστη
                        </Button>
                    </div>
                </form>}
                {status && <Success status={status}/>}
                {status && <Button color="primary" variant="contained" onClick={() => setStatus(undefined)} style={{ marginTop: 40, textTransform: 'capitalize'}}>
                    Πίσω
                </Button>}
            </Grid>
        </div>
    );
};

export default CreateUser;
