import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import authHeader from "../services/auth.header";
import Success from "./Success";
import Grid from "@material-ui/core/Grid";
import AuthService from '../services/auth.service';
import {useHistory} from "react-router-dom";


const validationSchema = yup.object({
    password: yup
        .string()
        .required('Ο παρών κωδικός είναι απαραίτητος'),
    newPassword: yup
        .string()
        .required('Ο νέος κωδικός είναι απαραίτητος')
        .min(8, 'Ο κωδικός πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες')
        .max(100, 'Ο κωδικός μπορεί να είναι μέχρι 100 χαρακτήρες')
        .matches('[0-9]', 'Ο κωδικός πρέπει να έχει τουλάχιστον έναν αριθμό')
        .matches('[A-Z]', 'Ο κωδικός πρέπει να έχει τουλάχιστον ένα κεφαλαίο γράμμα')
        .matches('[*@!#%&()^~{}]+', 'Ο κωδικός πρέπει να έχει τουλάχιστον έναν ειδικό χαρακτήρα'),
    newPasswordConfirmation: yup.string()
        .oneOf([yup.ref('newPassword'), null], 'Οι κωδικοί πρέπει να είναι ίδιοι')
});

const ChangePwScreen = (props) => {
    const [status, setStatus] = useState(undefined);
    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            password: '',
            newPassword: '',
            newPasswordConfirmation: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            axios.post(process.env.REACT_APP_API_URL + '/api/auth/changepw', values, {headers: authHeader()}).then(r => {
                if (r.status === 200) {
                    AuthService.changePw();
                    if (props.firstPw) {
                        return history.push('/');
                    }
                    setStatus('success');
                }
            }).catch(e => {
                setStatus('error')
            })
        },
    });

    return (
        <div>
            <Grid container spacing={3} justify="center" style={{background: '#ffffff', marginTop: 20}}>
                {!status && <form onSubmit={formik.handleSubmit}>
                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        label="Τρέχων κωδικός πρόσβασης"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        style={{ marginTop: 15}}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                    <TextField
                        fullWidth
                        id="newPassword"
                        name="newPassword"
                        label="Νέος κωδικός πρόσβασης - (ελάχιστο: 8 χαρακτήρες)"
                        type="password"
                        value={formik.values.newPassword}
                        style={{ marginTop: 15}}
                        onChange={formik.handleChange}
                        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                        helperText={(formik.touched.newPassword && formik.errors.newPassword) ? formik.errors.newPassword : 'Ο κωδικός πρέπει περιέχει τουλάχιστον ένα κεφαλαίο γράμμα, έναν αριθμό, έναν ειδικό χαρακτήρα'}
                    />
                    <TextField
                        fullWidth
                        id="newPasswordConfirmation"
                        name="newPasswordConfirmation"
                        label="Επαλήθευση νέου κωδικού πρόσβασης"
                        type="password"
                        value={formik.values.newPasswordConfirmation}
                        style={{ marginTop: 15}}
                        onChange={formik.handleChange}
                        error={formik.touched.newPasswordConfirmation && Boolean(formik.errors.newPasswordConfirmation)}
                        helperText={formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation}
                    />
                    <div style={{marginTop: 15}}>
                        <Button color="primary" variant="contained" type="submit" style={{ textTransform: 'capitalize' }}>
                            Ολοκλήρωση
                        </Button>
                    </div>
                </form>}
                {status && <Success status={status}/>}
                {status && <Button color="primary" variant="contained" onClick={() => setStatus(undefined)}
                                   style={{marginTop: 40, textTransform: 'capitalize'}}>
                    Πίσω
                </Button>}
            </Grid>
        </div>
    );
};

export default ChangePwScreen;
